$site-header-box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.5);

.site-header {
  @media only screen and (min-width: 768px) {
    display: flex;
    box-shadow: $site-header-box-shadow;
    justify-content: space-between;
    align-items: center;
  }
}

.site-header__main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $mobile-header-height;
  box-shadow: $site-header-box-shadow;

  @media only screen and (min-width: 768px) {
    box-shadow: none;
    height: auto;
  }
}

.site-header__logo {
  display: block;
  padding: 2rem;

  @media only screen and (min-width: 768px) {
    padding: 2.4rem;
  }

  svg {
    display: block;
    height: 1.6rem;

    @media only screen and (min-width: 768px) {
      height: 2rem;
    }

    path {
      fill: $black;
    }
  }
}

.site-header__nav-wrapper {
  max-height: 0;
  transition: max-height $animation-speed ease;
  overflow-y: hidden;
  background-color: $gray1;

  @media only screen and (min-width: 768px) {
    max-height: none;
    background-color: transparent;
  }

  .site-header--expanded & {
    max-height: 20rem; // screen height - header height

    @media only screen and (min-width: 768px) {
      max-height: none;
    }
  }
}
