* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6em;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
