.swatch-buttons {
  display: flex;
  flex-wrap: wrap;
}

.swatch-button {
  input {
    opacity: 0;
    position: absolute;
  }

  label {
    display: flex;
    width: 4.4rem;
    height: 4.4rem;
    border: solid 0.3rem transparent;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      border: solid $white;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg) translate(-0.2rem, -0.4rem);
      width: 0.8rem;
      height: 1.8rem;
      opacity: 0;
      transition: opacity 0.05s ease;
    }
  }

  input:checked + label::before {
    opacity: 1;
  }

  input:not(:checked) + label:hover::before {
    opacity: 0.2;
  }

  input:disabled + label {
    cursor: default;

    &::before,
    &:hover::before {
      opacity: 0;
    }
  }

  // light-color swatch overrides
  &.swatch-button--light {
    label {
      border-color: $gray2;
    }

    input:checked + label::before {
      border-color: $black;
    }
  }
}
