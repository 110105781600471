.site-nav {
  padding: 2rem;
  overflow-y: auto;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
}

.site-nav ul {
  margin: 0;
  padding: 0 2rem 0 0;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.site-nav li {
  display: block;
  list-style-type: none;
  margin-bottom: 1rem;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.site-nav a {
  display: block;
  font-size: 2rem;
  color: $white;
  padding: 1rem;

  @media only screen and (min-width: 768px) {
    color: $black;
    font-size: 1.8rem;
    padding: 2.4rem;
    border-top: solid 0.4rem transparent;
    border-bottom: solid 0.4rem transparent;
  }

  &:hover {
    @media only screen and (min-width: 768px) {
      text-decoration: none;
      border-bottom-color: $black;
    }
  }
}
