.menu-button {
  display: block;
  border: 0;
  background: none;
  outline: none;
  position: relative;

  width: 6rem;
  height: 6rem;
  padding: 2rem;
}

@media only screen and (min-width: 768px) {
  .menu-button {
    display: none !important;
  }
}

.menu-button__inner {
  text-indent: -9999px;
  position: absolute;
  width: 2rem;
  height: 0.2rem;
  background-color: $black;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color $animation-speed ease;
}

.menu-button--open .menu-button__inner {
  background-color: transparent;
}

.menu-button__inner::before,
.menu-button__inner::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background-color: $black;
  left: 0;
  transition: top $animation-speed ease,
    bottom $animation-speed ease,
    transform $animation-speed ease;
}

.menu-button__inner::before {
  top: -0.8rem;
}

.menu-button--open .menu-button__inner::before {
  top: 0;
  transform: rotate(45deg);
}

.menu-button__inner::after {
  bottom: -0.8rem;
}

.menu-button--open .menu-button__inner::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.menu-button__count {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: $red;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.menu-button__count::before {
  content: attr(data-cart-count);
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  color: $white;
}
