// colors
$black: #000000;
$gray1: #111111;
$gray2: #D7D7D7;
$white: #FFFFFF;
$red:   #D0021B;

// misc
$mobile-header-height: 6rem;
$animation-speed: 0.2s;
